import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',

        '& .MuiBadge-root': {
            marginRight: theme.spacing(4)
        }
    },
    count: {
        width: ' 20px',
        justifyContent: 'center',
        display: 'flex',
        border: '1px solid #F2F2F7',
        padding: '2px',
        borderRadius: '3px'
    }
}));

export default function QuantityBadge({ counter, handleCounter }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Button
                aria-label="reduce"
                onClick={() => handleCounter(counter - 1)}
                disabled={counter === 0}
                style={{ width: 10 }}
            >
                <RemoveIcon fontSize="small" />
            </Button>
            <span className={classes.count}> {counter}</span>
            <Button
                aria-label="increase"
                onClick={() => handleCounter(counter + 1)}
                style={{ width: 10 }}
            >
                <AddIcon fontSize="small" />
            </Button>
        </div>
    );
}
QuantityBadge.propTypes = {
    counter: PropTypes.number,
    handleCounter: PropTypes.func
};
