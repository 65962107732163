import {
    createOrder,
    getSalesChannelsConfig,
    resetToken,
    saveStep1,
    saveStep2,
    saveStep3,
    saveStep4,
    signUp,
    uploadAttachements
} from '../redux/order/actions';
import { connect } from 'react-redux';
import Order from './../pages/Order';
import {
    getCodeCourse,
    getCreated,
    getLoading,
    getSalesChannelConfig,
    getSignUpError,
    getStep1Detail,
    getStep2Detail,
    getStep3Detail,
    getStep4Detail,
    getToken
} from '../redux/order/selectors';
const mapStateToProps = (state) => ({
    step1: getStep1Detail(state),
    step2: getStep2Detail(state),
    step3: getStep3Detail(state),
    step4: getStep4Detail(state),
    loading: getLoading(state),
    created: getCreated(state),
    codeCourse: getCodeCourse(state),
    salesChannelConfig: getSalesChannelConfig(state),
    token: getToken(state),
    signUpError: getSignUpError(state)
});

const mapDisptachToProps = (dispatch) => ({
    saveStep1: (step) => dispatch(saveStep1(step)),
    saveStep2: (step) => dispatch(saveStep2(step)),
    saveStep3: (step) => dispatch(saveStep3(step)),
    saveStep4: (step) => dispatch(saveStep4(step)),
    createOrder: () => dispatch(createOrder()),
    uploadAttachements: () => dispatch(uploadAttachements()),
    getSalesChannelsConfig: () => dispatch(getSalesChannelsConfig()),
    signUp: (clientId, clientSecret) => dispatch(signUp(clientId, clientSecret)),
    resetToken : ()=> dispatch( resetToken())
});

export default connect(mapStateToProps, mapDisptachToProps)(Order);
