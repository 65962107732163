import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';

import RoomIcon from '@material-ui/icons/Room';
import './style.scss';

import { Country, GOOGLE_COUNTRIES, GOOGLE_DEFAULT_COUNTRY } from '../../utils';
import axios from 'axios';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import queryString from 'query-string';

export default function AddressAutoComplete({
    label,
    handleOnAddressSelect,
    handleQueryChange,
    selectedDefaultAddress,
    // geoCachingAddressPredictions,
    hasError,
    errorText,
    isDisabled
}) {
    const inputRef = useRef();
    const [isLoaded, setIsLoaded] = useState(false);
    const [query, setQuery] = useState('');
    const [predictions, setPredictions] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(null);
    const countries = GOOGLE_COUNTRIES.map((c) => {
        c.code = c.code.toLowerCase();
        return c;
    }).sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const [selectedCountry, setSelectedCountry] = useState(
        countries.find((c) => c.code === GOOGLE_DEFAULT_COUNTRY.code)
    );

    const useKeyPress = function (targetKey) {
        const [keyPressed, setKeyPressed] = useState(false);

        function downHandler({ key }) {
            if (key === targetKey) {
                setKeyPressed(true);
            }
        }

        const upHandler = ({ key }) => {
            if (key === targetKey) {
                setKeyPressed(false);
            }
        };

        React.useEffect(() => {
            window.addEventListener('keydown', downHandler);
            window.addEventListener('keyup', upHandler);

            return () => {
                window.removeEventListener('keydown', downHandler);
                window.removeEventListener('keyup', upHandler);
            };
        });

        return keyPressed;
    };
    const downPress = useKeyPress('ArrowDown');
    const upPress = useKeyPress('ArrowUp');
    const enterPress = useKeyPress('Enter');
    const [cursor, setCursor] = useState(0);

    useEffect(() => {
        if (isMenuOpen && predictions?.length && downPress) {
            setCursor((prevState) =>
                prevState < predictions?.length - 1 ? prevState + 1 : prevState
            );
        }
    }, [downPress, isMenuOpen, predictions]);
    useEffect(() => {
        if (isMenuOpen && predictions?.length && upPress) {
            setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
        }
    }, [upPress, isMenuOpen, predictions]);

    useEffect(() => {
        if (isMenuOpen && predictions?.length && enterPress) {
            handleClose(null, predictions[cursor]);
        }
    }, [cursor, enterPress]);
    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const onInputChange = (ev) => {
        setQuery(ev.target.value);
        if (!isMenuOpen) {
            setIsMenuOpen(true);
        }
    };

    const handleClose = (e, address) => {
        let queryAddress = '';
        if (e !== null) {
            e.preventDefault();
        }
        if (address) {
            handleOnAddressSelect(address);
        } else {
            handleOnAddressSelect(null);
        }
        if (address) {
            queryAddress = address.formatted_address;
            setQuery(queryAddress);
            setIsMenuOpen(false);
        }
    };
    useEffect(() => {
        if (!_.isNil(selectedDefaultAddress)) {
            setQuery(selectedDefaultAddress.formattedAddress);
            if (_.get(selectedDefaultAddress, 'country.code')) {
                const selectedCountry = countries.find(
                    (c) => c.code === selectedDefaultAddress.country.code.toLowerCase()
                );
                setSelectedCountry(selectedCountry);
            } else {
                setSelectedCountry(
                    new Country(GOOGLE_DEFAULT_COUNTRY.code, GOOGLE_DEFAULT_COUNTRY.name)
                );
            }
        } else {
            setQuery('');
        }
        handleClose(null, selectedDefaultAddress);
    }, [selectedDefaultAddress]);

    useEffect(() => {
        handleQueryChange(query);
    }, [query]);

    useEffect(() => {
        if (!isMenuOpen && isLoaded) {
            inputRef.current.blur();
        }
    }, [isMenuOpen]);

    const getAutoCompleteAddress = () => {
        if (query && query.trim().length && isMenuOpen) {
            const countryParam = !_.isNil(selectedCountry.code)
                ? selectedCountry.code.toLowerCase()
                : GOOGLE_COUNTRIES.find(
                    (c) => c.code === GOOGLE_DEFAULT_COUNTRY.code
                ).code.toLowerCase();
            const dataSend = {
                query: query,
                country: countryParam
            };
            axios
                .get(
                    `https://geocaching.limone.fr/api/places?${queryString.stringify(
                        dataSend
                    )}`
                )
                .then(({ data }) => {
                    const list = data.predictions.map(({ geoc }) => geoc);
                    if (_.get(list, 'length')) {
                        setPredictions([...list]);
                    } else {
                        setPredictions([]);
                    }
                })
                .catch((err) => {
                    console.log('err', err);
                });
        } else {
            setPredictions([]);
        }
    };

    useEffect(() => {
        const inputchangeTimer = setTimeout(() => {
            getAutoCompleteAddress();
        }, 400);
        return () => {
            clearTimeout(inputchangeTimer);
        };
    }, [query]);

    // const handleBlur = (e) => {
    //     if (isMenuOpen) {
    //         const { value } = e.target;
    //         setTimeout(() => {
    //             if (_.get(geoCachingAddressPredictions, 'length')) {
    //                 const address = geoCachingAddressPredictions.find(
    //                     (a) => a.formattedAddress === value
    //                 );
    //                 address ? handleClose(null, address) : handleClose(null, null);
    //             } else {
    //                 handleClose(null, null);
    //             }
    //         }, 2);
    //     }
    // };
    const theme = useTheme();
    return (
        <>
            {isLoaded ? (
                <div className="auto-complete-address">
                    <TextField
                        id={`address`}
                        placeholder={label}
                        variant="outlined"
                        fullWidth={true}
                        ref={inputRef}
                        className="auto-complete-input"
                        onChange={onInputChange}
                        onFocus={() => setIsMenuOpen(true)}
                        onClick={() => setIsMenuOpen(true)}
                        // onBlur={($event) => handleBlur($event)}
                        value={query}
                        error={hasError}
                        disabled={isDisabled}
                        autoComplete="off"
                        helperText={errorText}
                        InputLabelProps={{
                            shrink: false
                        }}
                    />
                    {predictions && predictions.length > 0 ? <div className='auto-complete-address-list'>
                        <ul >
                            {predictions.map((a, index) => (
                                <li
                                    className="active"
                                    onMouseDown={($event) => handleClose($event, a)}
                                    key={index}
                                >
                                    <RoomIcon
                                        // color={theme.palette.primary.secondary}
                                        style={{
                                            color: theme.palette.primary.secondary
                                        }}
                                    />
                                    <span className="address">{a.formatted_address}</span>
                                </li>
                            ))
                            }
                        </ul>
                    </div> : ''}
                </div>
            ) : null}
        </>
    );
}
AddressAutoComplete.propTypes = {
    label: PropTypes.string,
    handleOnAddressSelect: PropTypes.func,
    handleQueryChange: PropTypes.func,
    selectedDefaultAddress: PropTypes.object,
    geoCachingAddressPredictions: PropTypes.array,
    hasError: PropTypes.bool,
    isRequired: PropTypes.bool,
    errorText: PropTypes.string,
    isDisabled: PropTypes.bool
};
