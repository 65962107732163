import { Button, List, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './index.scss';
import { makeStyles } from '@material-ui/core/styles';
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
    FormControl,
    InputLabel
} from '@material-ui/core';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import BacArrow from '../../assets/images/backarrow.svg';
import arrowdown from '../../assets/images/arrowdown.svg';
import arrowup from '../../assets/images/arrowup.svg';

import { statutList, ageList } from '../../utils';
import StateModal from '../../components/StateModal';


const useStyles = makeStyles(() => ({
    root: {
        position: 'unset',
        marginTop: '10px !important',
        // boxShadow: '0 4px 4px 0 #f5f5f9',
        '& .MuiTypography-displayBlock': {
            color: '#878799',
            fontWeight: '700'
        }
    },
    listIcon: {
        paddingInline: '7px',
        width: '17px'
    },
    forward: {
        borderRadius: '10px',
        marginRight: '15px'
    },
    summary: {
        // boxShadow: '0 4px 4px 0 #f5f5f9',
        borderRadius: '5px',
        color: 'white'
    },

    details: {
        display: 'block',
        borderRadius: '5px'
    },
    example: {
        // boxShadow: '0 4px 4px 0 #f5f5f9',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#F0F3FA'
        }
    },
    text: {
        color: '#878799',
        fontWeight: 700
    },
    warningText: {
        fontSize: 12,
        padding: '5px 0 0 5px',
        color: '#ba000d'
    },
    container: {
        boxShadow: '0px 0px 12px #B8B8D233',
        borderRadius: '12px',
        opacity: 1,
        padding: 10,
        marginBottom: 15,
        height: '310px',
        margin: '5px'
    },
    choiceButton: {
        height: '50px',
        background: '#FFFFFF',
        border: '0.5px solid rgb(225 225 225 / 54%)',
        borderRadius: '12px',
        opacity: 1,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#F0F3FA !important'
        },
        '& .MuiButton-label': {
            display: 'flex',
            justifyContent: 'space-between',
            textTransform: 'initial !important'
        }
    },
    contain: {
        marginBottom: '30px !important'
    }

}));
export default function Step2({ nextStep, previousStep, save, step, data }) {

    const [dataSend, setsavedData] = useState(data);
    const [canGoNext, setcanGoNext] = useState(false);
    const [ageHasError, setAgeHasError] = useState(false);
    const [statusHasError, setStatusHasError] = useState(false);
    const [nameSelected, setnameSelected] = useState(null);
    const [open, setOpen] = useState(false);
    const [list, setList] = useState([]);
    const [idProduit, setIdProduit] = useState(null);
    const [idAppareil, setIdAppareil] = useState(null);
    const classe = useStyles();
    useEffect(() => {
        ReactGA.event({
            category: 'Utilisateur',
            action: 'Ecosystem - Step 2'
        });
    }, []);

    useEffect(() => {
        let dataStep1 = 0;
        let datastep2 = 0;
        if (step) {
            Object.values(dataSend).map((article) => { return dataStep1 += article.quantity; });
            Object.values(step.allData).map((article) => { return datastep2 += article.quantity; });
            if (datastep2 == dataStep1) {
                setsavedData(step.allData);
            }
        }



    }, [step]);
    const handleChangeState = (value, produitId, appareilId) => {

        dataSend[appareilId].produits[produitId] = {
            state: value,
            age: dataSend[appareilId].produits[produitId].age,
            label: dataSend[appareilId].label,
            code: dataSend[appareilId].code,
            quantity: 1,
            idProduit: produitId
        };
        setsavedData({ ...dataSend });


        setStatusHasError(value === '');
        setcanGoNext(false);
    };
    const handleChangeAge = (value, produitId, appareilId) => {

        dataSend[appareilId].produits[produitId] = {
            state: dataSend[appareilId].produits[produitId].state,
            age: value,
            label: dataSend[appareilId].label,
            code: dataSend[appareilId].code,
            quantity: 1,
            idProduit: produitId
        };
        setsavedData({ ...dataSend });


        setAgeHasError(value === '');
        setcanGoNext(false);
    };
    const validateAll = () => {
        let products = [];
        Object.values(dataSend).map((element) => {
            Object.values(element.produits).map((obj) => {
                products.push(obj);
            });
        });
        const allDone = products.every(
            (val) =>
                (val?.age !== undefined && val.age !== null) &&
                (val?.state !== undefined && val?.state !== null)
        );
        setcanGoNext(!allDone);

        if (allDone) {
            nextStep();

            save({ allData: dataSend });
        }
    };
    const handleClickOpen = (ageList, name, IdProduit, idAppareil) => {
        setOpen(true);
        setList(ageList);
        setnameSelected(name);
        setIdProduit(IdProduit);
        setIdAppareil(idAppareil);
    };

    const handleClose = () => {
        setOpen(false);
        setList([]);
        setnameSelected(null);
        setIdProduit(null);
        setIdAppareil(null);
    };
    return (<>

        <div style={{ background: '#FDFDFD', borderTop: '1px solid #F7F7F7', borderRight: '1px solid #F7F7F7', borderLeft: '1px solid #F7F7F7' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={BacArrow}
                    onClick={previousStep}
                    style={{ marginTop: '15px', paddingRight: '13px', cursor: 'pointer', marginLeft: '8px' }}
                />
                <Typography style={{ fontSize: 19, fontWeight: 500, marginTop: 15 }}>
                    {' '}
                    Parlez-nous de votre appareil
                </Typography>
            </div>

            <List style={{
                height: '480px',
                overflow: 'auto'
            }}>
                {Object.values(dataSend).map((element) => {
                    return (
                        <>
                            <div className={classe.root}>
                                <div
                                    className={classe.summary}
                                >
                                    <div className={classe.details}>
                                        {Object.values(element.produits).map((x, i) => {
                                            return (
                                                <div key={i} className={classe.container}>
                                                    <ListItem component="div" style={{ margin: ' 10px 0px' }}>
                                                        <ListItemAvatar>
                                                            <img src={element.src} alt={element.label} style={{ width: 50 }} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={`${element.label} (${i + 1})`}
                                                            style={{ whiteSpace: 'pre-wrap', color: 'black' }}
                                                        />
                                                    </ListItem>
                                                    <div>
                                                        <div className={classe.contain}>
                                                            <InputLabel id="demo-mutiple-name-label" className="field-title">
                                                                Sélectionnez l’état *
                                                            </InputLabel>
                                                            <FormControl variant="outlined" fullWidth >
                                                                <Button
                                                                    variant="contained"
                                                                    className={classe.choiceButton}
                                                                    onClick={() => {
                                                                        handleClickOpen(
                                                                            statutList,
                                                                            ' Sélectionnez l’état',
                                                                            x.idProduit,
                                                                            element.id

                                                                        );
                                                                    }}
                                                                >
                                                                    {dataSend[element.id].produits[x.idProduit].state || 'Sélectionnez l’état'}
                                                                    {open && x.idProduit === idProduit ?
                                                                        <img
                                                                            src={arrowdown}
                                                                            style={{ width: '15px' }}

                                                                        /> : <img
                                                                            src={arrowup}
                                                                            style={{ width: '15px' }}

                                                                        />}


                                                                </Button>
                                                            </FormControl>
                                                            {statusHasError && (
                                                                <Typography className={classe.warningText}>
                                                                    Veuillez sélectionner un statut
                                                                </Typography>
                                                            )}
                                                        </div>
                                                        <div className={classe.contain}>
                                                            <InputLabel id="demo-mutiple-name-label" className="field-title">
                                                                Sélectionnez l’âge *
                                                            </InputLabel>
                                                            <FormControl variant="outlined" fullWidth>

                                                                <Button
                                                                    variant="contained"
                                                                    className={classe.choiceButton}
                                                                    onClick={() => {
                                                                        handleClickOpen(
                                                                            ageList,
                                                                            ' Sélectionnez l’âge',
                                                                            x.idProduit,
                                                                            element.id
                                                                        );
                                                                    }}
                                                                >
                                                                    {dataSend[element.id].produits[x.idProduit].age || ' Sélectionnez l’âge'}
                                                                    {open && x.idProduit == idProduit ?
                                                                        <img
                                                                            src={arrowdown}

                                                                            style={{ width: '15px' }}

                                                                        /> : <img
                                                                            src={arrowup}

                                                                            style={{ width: '15px' }}

                                                                        />}
                                                                </Button>

                                                            </FormControl>
                                                            {ageHasError && (
                                                                <Typography className={classe.warningText}>
                                                                    Veuillez sélectionner âge
                                                                </Typography>
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                            </div>
                        </>
                    );
                })}
                {open && (
                    <StateModal
                        open={open}
                        handleClose={handleClose}
                        name={nameSelected}
                        list={list}
                        validata={nameSelected.includes('état') ? handleChangeState : handleChangeAge}
                        idProduit={idProduit}
                        idAppareil={idAppareil}
                    />
                )}
            </List>
            {canGoNext && (
                <Typography className={classe.warningText}>
                    « vous devez remplir les informations de tous les appareils »
                </Typography>
            )}


        </div>
        <div style={{
            display: 'flex', justifyContent: 'end', boxShadow: ' rgb(116 116 116 / 54%) 0px 0px 12px'
            ,
            padding: ' 10px 10px 10px'
        }}>
            <Button style={{
                width: '192px',
                height: '50px',
                backgroundColor: '#FF6C23',
                borderRadius: '8px',
                opacity: 1,
                color: '#ffff'
            }} onClick={validateAll}  >
                Suivant
            </Button>
        </div>
    </>
    );
}
Step2.propTypes = {
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    save: PropTypes.func,
    step: PropTypes.object,
    data: PropTypes.object,
    dataSend: PropTypes.array,
    setsavedData: PropTypes.array
};
