import React from 'react';
import './index.css';
import Order from './../../containers/order';

function App() {
    return (
        <div className="PublicaPlay-Light">
            <Order />
        </div>
    );
}

export default App;
