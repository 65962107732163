import initialState from './initialState';
import {
    CREATE_ORDER,
    CREATE_ORDER_FAILURE,
    CREATE_ORDER_SUCCESS,
    GET_SALESCHANNEL_CONFIG,
    GET_SALESCHANNEL_CONFIG_SUCCESS,
    RESET_TOKEN,
    SAVE_STEP_1,
    SAVE_STEP_2,
    SAVE_STEP_3,
    SAVE_STEP_4,
    SIGNUP,
    SIGNUP_FAILURE,
    SIGNUP_SUCCESS
} from './actions';

const OrderReducer = (state = initialState(), action) => {
    if (action.type === SAVE_STEP_1) {
        return {
            ...state,
            step1: action.payload
        };
    }
    if (action.type === SAVE_STEP_2) {
        return {
            ...state,
            step2: action.payload
        };
    }
    if (action.type === SAVE_STEP_3) {
        return {
            ...state,
            step3: action.payload
        };
    }
    if (action.type === SAVE_STEP_4) {
        return {
            ...state,
            step4: action.payload
        };
    }
    if (action.type === CREATE_ORDER) {
        return {
            ...state,
            loading: true,
            created: false,
            codeCourse: null
        };
    }
    if (action.type === CREATE_ORDER_SUCCESS) {
        const { code, response } = action.payload.data;
        return {
            ...state,
            loading: false,
            created: code < 400,
            codeCourse: response
        };
    }
    if (action.type === CREATE_ORDER_FAILURE) {
        return {
            ...state,
            loading: false,
            created: false,
            codeCourse: null
        };
    }

    if (action.type === GET_SALESCHANNEL_CONFIG) {
        return {
            ...state,
            loading: true,
            salesChannelConfig: null
        };
    }

    if (action.type === GET_SALESCHANNEL_CONFIG_SUCCESS) {
        const {
            response: {
                configs: { operationalHours, hourMinBooking }
            }
        } = action.payload.data;
        return {
            ...state,
            loading: false,
            salesChannelConfig: { operationalHours, hourMinBooking }
        };
    }
    if (action.type === SIGNUP) {
        return {
            ...state,
            token: null
        };
    }
    if (action.type === SIGNUP_SUCCESS) {
        const token = action?.payload?.data?.Response?.access_token || null;
        console.log('token===>', token);
        return {
            ...state,
            token,
            signUpError: token ? null : "Une erreur s'est produite"
        };
    }
    if (action.type === SIGNUP_FAILURE) {
        return {
            ...state,
            token: null,
            signUpError: "Une erreur s'est produite"
        };
    }
    if (action.type === RESET_TOKEN) {
        return {
            ...state,
            token: null
        };
    }
    return state;
};
export default OrderReducer;
