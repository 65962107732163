import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/App/index';
import reportWebVitals from './reportWebVitals';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Provider } from 'react-redux';
import store from './redux/store';
import ReactGA from 'react-ga';
const TRACKING_ID = 'UA-76669830-5';
ReactGA.initialize(TRACKING_ID);
const theme = createTheme({
    palette: {
        primary: {
            main: '#ccc',
            secondary: '#f37547',
            transparent: 'transparent'
        },
        secondary: {
            main: '#f37547'
        }
    }
});
ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <App />
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
