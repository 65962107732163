import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import React, { useEffect, useState } from 'react';
import frLocale from 'date-fns/locale/fr';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';
import './index.scss';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core';
import PrimaryButton from '../../components/Button';
import {
    DAYS_OF_WEEK,
    getCreneau,
    getDaysIndex,
    getMinDate
} from '../../utils';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import BacArrow from '../../assets/images/backarrow.svg';
const localeMap = {
    fr: frLocale
};
const useStyles = makeStyles(() => ({
    root: {
        padding: '0px 5px 0px 5px'
    },
    warningMsg: {
        paddingLeft: 5,
        fontSize: 13,
        color: 'dimgray',
        paddingTop: 5
    },
    title: {
        fontSize: 30,
        fontWeight: 400,
        padding: '10px 2px'
    },
    subTitle: {
        fontSize: 15,
        fontWeight: 400,
        paddingBottom: 10,
        paddingTop: 10
    }
}));
const Step4 = ({
    nextStep,
    previousStep,
    save,
    step,
    createOrder,
    salesChannelConfig
}) => {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(null);
    const [time, setTime] = useState('');
    const [text, setText] = useState(true);
    const [text2, setText2] = useState(false);
    const [text3, setText3] = useState(false);
    const [pickerStatus, setPickerStatus] = useState(false);
    const [hoursList, setHoursList] = useState([]);
    const [disabledDays, setDisabledDays] = useState([]);
    const [minDate, setMinDate] = useState(new Date());
    useEffect(() => {
        if (salesChannelConfig) {
            const list = getDaysIndex(salesChannelConfig.operationalHours);
            setDisabledDays(list);
            setMinDate(new Date(getMinDate(salesChannelConfig.hourMinBooking, list)));
        }

        ReactGA.event({
            category: 'Utilisateur',
            action: 'Ecosystem - Step 4'
        });
    }, []);
    useEffect(() => {
        if (selectedDate) {
            const start = moment(selectedDate).tz('Europe/Paris', true);
            filterCreneau(start);
        }
    }, [selectedDate]);

    useEffect(() => {
        if (step) {
            const start = moment(step.start).tz('Europe/Paris', true);
            filterCreneau(start);
            setSelectedDate(start);
        }
    }, [step]);
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleTimeChange = (event) => {
        setTime(event.target.value);
    };
    const disableDay = (date, disableDays) => {
        if (salesChannelConfig) {
            if (disableDays.findIndex((d) => d === date.getDay()) >= 0) {
                return true;
            } else {
                if (
                    salesChannelConfig?.operationalHours &&
                    salesChannelConfig?.operationalHours.length > 0
                ) {
                    const today = moment(new Date()).format('YYYY-MM-DD');
                    const theDay = moment(date).format('YYYY-MM-DD');
                    const theMinDay = moment(minDate).format('YYYY-MM-DD');
                    if (today === theDay) {
                        let filteredCreneau = [];
                        const start = moment(today).tz('Europe/Paris', true);
                        const dayIndex = moment(start).day();
                        const weekDay = DAYS_OF_WEEK[dayIndex];
                        let creneau = [];
                        const filteredDays = salesChannelConfig.operationalHours.filter(
                            ({ dayOfWeek }) => dayOfWeek.code === weekDay
                        );
                        if (filteredDays.length > 0) {
                            filteredDays.forEach(
                                ({ closeTime, deliveryWindow, openTime }) => {
                                    let creneauList = getCreneau(
                                        start,
                                        closeTime,
                                        deliveryWindow,
                                        openTime
                                    );
                                    creneau = creneau.concat(creneauList);
                                }
                            );
                            const minHour = moment(minDate).format('HH');
                            creneau.forEach((element) => {
                                const nbrMinHour = parseInt(minHour);
                                const start = element.value.split('-');
                                const startHour = parseInt(start[0]);
                                if (nbrMinHour <= startHour) {
                                    filteredCreneau.push(element);
                                }
                            });
                        }
                        return filteredCreneau.length === 0;
                    } else if (theMinDay === theDay) {
                        let filteredCreneau = [];
                        const start = moment(date).tz('Europe/Paris', true);
                        const dayIndex = moment(start).day();
                        const weekDay = DAYS_OF_WEEK[dayIndex];
                        let creneau = [];
                        const filteredDays = salesChannelConfig.operationalHours.filter(
                            ({ dayOfWeek }) => dayOfWeek.code === weekDay
                        );
                        if (filteredDays.length > 0) {
                            filteredDays.forEach(
                                ({ closeTime, deliveryWindow, openTime }) => {
                                    let creneauList = getCreneau(
                                        start,
                                        closeTime,
                                        deliveryWindow,
                                        openTime
                                    );
                                    creneau = creneau.concat(creneauList);
                                }
                            );
                        }
                        const minHour = moment(minDate).format('HH');
                        creneau.forEach((element) => {
                            const nbrMinHour = parseInt(minHour);
                            const start = element.value.split('-');
                            const startHour = parseInt(start[0]);
                            if (nbrMinHour <= startHour) {
                                filteredCreneau.push(element);
                            }
                        });
                        return filteredCreneau.length === 0;
                    }
                    return false;
                } else {
                    return false;
                }
            }
        } else {
            return disableDays.findIndex((d) => d === date.getDay()) >= 0;
        }
    };
    const validate = () => {
        const times = time.split('-');
        const start = moment(selectedDate)
            .tz('Europe/Paris', true)
            .startOf('day')
            .add(parseInt(times[0], 10), 'hours')
            .format();
        const end = moment(selectedDate)
            .tz('Europe/Paris', true)
            .startOf('day')
            .add(parseInt(times[1], 10), 'hours')
            .format();
        nextStep();
        save({ start, end });
        createOrder();
    };
    const filterCreneau = (start) => {
        if (
            salesChannelConfig?.operationalHours &&
            salesChannelConfig?.operationalHours.length > 0
        ) {
            const startHour = moment(start).format('HH');
            const dayIndex = moment(start).day();
            const weekDay = DAYS_OF_WEEK[dayIndex];
            let creneau = [];
            let filteredCreneau = [];
            const filteredDays = salesChannelConfig.operationalHours.filter(
                ({ dayOfWeek }) => dayOfWeek.code === weekDay
            );
            if (filteredDays.length > 0) {
                filteredDays.forEach(({ closeTime, deliveryWindow, openTime }) => {
                    let creneauList = getCreneau(
                        start,
                        closeTime,
                        deliveryWindow,
                        openTime
                    );
                    creneau = creneau.concat(creneauList);
                });
                const isAfter =
                    moment(new Date(start)).format('YYYY-MM-DD') >
                    moment(minDate).format('YYYY-MM-DD');
                if (!isAfter) {
                    const minHour = moment(minDate).format('HH');
                    creneau.forEach((element) => {
                        const nbrMinHour = parseInt(minHour);
                        const start = element.value.split('-');
                        const startHour = parseInt(start[0]);
                        if (nbrMinHour <= startHour) {
                            filteredCreneau.push(element);
                        }
                    });
                    setHoursList(filteredCreneau);
                    let selectedTime = '';
                    filteredCreneau.forEach((elem) => {
                        const hr = elem.value.substring(0, 2);
                        if (hr === startHour) {
                            selectedTime = elem.value;
                        }
                    });
                    setTime(selectedTime);
                } else {
                    setHoursList(creneau);
                    let selectedTime = '';
                    creneau.forEach((elem) => {
                        const hr = elem.value.substring(0, 2);
                        if (hr === startHour) {
                            selectedTime = elem.value;
                        }
                    });
                    setTime(selectedTime);
                }
            }
        }
    };
    return (

        <>
            <div className={['step4-container', classes.root].join(' ')}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={BacArrow}
                        onClick={previousStep}
                        style={{ marginTop: '15px', paddingRight: '13px', cursor: 'pointer' }}
                    />
                    <Typography style={{ fontSize: 20, fontWeight: 500, marginTop: 15 }}>
                        {' '}
                        Votre rendez-vous
                    </Typography>
                </div>
                <Typography className={classes.subTitle}>
                    {' '}
                    Sélectionnez le RDV de votre choix{' '}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['fr']}>
                    <KeyboardDatePicker
                        style={{ width: '100%' }}
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        margin="normal"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label="Date"
                        value={selectedDate}
                        disablePast={true}
                        onChange={handleDateChange}
                        shouldDisableDate={($event) => disableDay($event, disabledDays)}
                        onClick={() => setPickerStatus(true)}
                        onClose={() => setPickerStatus(false)}
                        open={pickerStatus}
                        autoOk={true}
                        minDate={minDate}
                    />
                </MuiPickersUtilsProvider>
                <div>
                    <FormControl variant="outlined" style={{ marginTop: 20, width: '100%' }}>
                        <InputLabel id="demo-simple-select-outlined-label">
                            Créneau de collecte
                        </InputLabel>

                        <Select
                            value={time}
                            onChange={handleTimeChange}
                            label="Créneau de collecte"
                            style={{ width: '100%' }}
                            disabled={!selectedDate || hoursList.length === 0}
                        >
                            {hoursList.map(({ label, value }) => (
                                <MenuItem
                                    key={value}
                                    value={value}
                                    style={{ fontSize: 14 }}
                                    selected={value === time.value}
                                >
                                    <span style={{ paddingLeft: 10 }}>{label}</span>
                                </MenuItem>
                            ))}
                        </Select>
                        {hoursList.length === 0 && selectedDate && (
                            <Typography className={classes.warningMsg}>
                                Aucune heure disponible à cette date!
                            </Typography>
                        )}
                    </FormControl>
                </div>
                <div style={{ padding: '15px 6px' }}>
                    <FormGroup>
                        <FormControlLabel
                            style={{ padding: '10px 0' }}
                            control={
                                <Checkbox
                                    checked={text}
                                    onChange={() => setText(!text)}
                                    inputProps={{
                                        'aria-label': 'primary checkbox'
                                    }}
                                />
                            }
                            label="Je cède gratuitement et de manière définitive mon ou mes équipements à ecosystem. "
                        />
                        <FormControlLabel
                            style={{ padding: '10px 0' }}
                            control={
                                <Checkbox
                                    checked={text2}
                                    onChange={() => setText2(!text2)}
                                    inputProps={{
                                        'aria-label': 'primary checkbox'
                                    }}
                                />
                            }
                            label="Je certifie sur l’honneur l’exactitude des renseignements fournis et m’engage à être disponible à la date et sur le créneau horaire choisis. "
                        />
                        <FormControlLabel
                            style={{ padding: '10px 0' }}
                            control={
                                <Checkbox
                                    checked={text3}
                                    onChange={() => setText3(!text3)}
                                    inputProps={{
                                        'aria-label': 'primary checkbox'
                                    }}
                                />
                            }
                            label="Je m’engage à ce que mon électroménager soit débranché et accessible pour les équipes ecosystem."
                        />
                    </FormGroup>
                </div>

            </div>
            <PrimaryButton
                next={validate}
                hasPrevious={false}
                label={'JE VALIDE'}
                disabled={!text || !text2 || !text3 || !selectedDate || time === ''}
            />
        </>
    );
};
Step4.propTypes = {
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    save: PropTypes.func,
    step: PropTypes.object,
    createOrder: PropTypes.func,
    salesChannelConfig: PropTypes.object,
    signUpError: PropTypes.any
};
export default Step4;
