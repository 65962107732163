const initialState = () => {
    return {
        step1: null,
        step2: null,
        step3: null,
        step4: null,
        attachements: null,
        loading: false,
        created: false,
        codeCourse: null,
        salesChannelConfig: null,
        token: null,
        signUpError: null
    };
};
export default initialState;
