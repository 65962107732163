import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import confirmed from './../../assets/Confirmed.svg';
import './index.scss';
import ReactGA from 'react-ga';
const Step5 = () => {
    useEffect(() => {
        ReactGA.event({
            category: 'Utilisateur',
            action: 'Ecosystem - Fin'
        });
    }, []);
    return (
        <div style={{  padding: '5px 0px 10px' }}>
            <div>
                <Typography
                    style={{
                        fontSize: 30,
                        fontWeight: 400,
                        padding: '30px 5px 15px',
                        textAlign: 'center'
                    }}
                >
                    {"C'est tout bon !"}
                </Typography>
                <img
                    src={confirmed}
                    alt={'confirmed'}
                    style={{
                        // width: 200,
                        margin: 'auto 40px',
                        paddingBottom: 20
                    }}
                />
                <Typography
                    style={{
                        fontSize: 15,
                        fontWeight: 400,
                        padding: '5px 15px',
                        textAlign: 'center'
                    }}
                >
                    {' '}
          Votre formulaire est maintenant complet. Nous vous remercions de nous
          confier votre appareil.
                </Typography>
            </div>
        </div>
    );
};
export default Step5;
