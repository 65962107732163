import React, { useEffect } from 'react';
import { InputLabel, makeStyles, Typography } from '@material-ui/core';
import './index.scss';

import AddressAutoComplete from '../AddressAutoComplete';
 import PrimaryButton from '../../components/Button';
import VerticalScroll from './../../components/VerticalScroll';
import {
    getAddressPostalCode,
    AVAILABLE_POSTAL_CODES,
    LIST,
    AVAILABLE_POSTAL_CODES_GROUPE
} from '../../utils';
import ReactGA from 'react-ga';

import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    item: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    img: {
        width: 50
    },
    itemText: {
        paddingTop: 8
    },
    errorText: {
        fontSize: 11,
        color: 'red',
        textAlign: 'left',
        paddingLeft: 5
    },
    itemsList: {
        maxHeight: 219,
        overflowY: 'scroll'
    },
    warningText: {
        fontSize: 12,
        padding: '5px 0 0 5px',
        color: '#ba000d'
    }
}));


const Step1 = ({ nextStep, save, step }) => {
    const classes = useStyles();
    const [list, setList] = React.useState([]);
    const [address, setAddress] = React.useState(null);
    const [addressHasError, setAddressHasEror] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);
    const [hasErrorAddress, setHasErrorAddress] = React.useState(false);
    const [available, setAvailable] = React.useState(true);
    useEffect(() => {
        ReactGA.event({
            category: 'Utilisateur',
            action: 'Ecosystem - Step 1'
        });

        // const listSorted = LIST.sort(function (a, b) {
        //     return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
        // });
        setList(LIST);
    }, []);
    useEffect(() => {
        if (step) {
            verifyAddress(step.address);
            setList(step.articles);
        }
    }, [step]);
    const handleChange = (id, quantity) => {
        const itemsList = list.map((element) => {
            return {
                ...element,
                quantity: element.id === id ? quantity : element.quantity
            };
        });
        setList(itemsList);
        const filtered = itemsList.filter(({ quantity }) => quantity);
        setHasError(filtered.length === 0);
    };
    const handleAddressChange = (value) => {
        if (typeof value === 'object' && value) {
            verifyAddress(value);
        }
    };
    const validateData = () => {
        const selected = list.filter(({ quantity }) => quantity > 0);
        setHasError((selected.length === 0));
        setHasErrorAddress(!addressHasError);
        if (address && selected.length > 0 && !addressHasError && available) {
            nextStep();
            save({ address, articles: list });
        }
    };
    const verifyAddress = (value) => {
        setAddress(value);
        const postalCode = getAddressPostalCode(value);
        if (postalCode) {
            const isAvailableInList =
                AVAILABLE_POSTAL_CODES.includes(postalCode) ||
                AVAILABLE_POSTAL_CODES_GROUPE.includes(postalCode.substr(0, 2));
            setAvailable(isAvailableInList);
            setAddressHasEror(!isAvailableInList);
            setHasErrorAddress(!isAvailableInList);
        } else {
            setAddressHasEror(true);
            setHasErrorAddress(true);
        }
    };
    return (
        <>
            <div className="step1-container">
                <form
                    noValidate
                    autoComplete="off"
                    style={{ width: '100%' }}
                >
                    <InputLabel id="demo-mutiple-name-label" className="field-title">
                        Votre adresse(*):
                    </InputLabel>
                    <AddressAutoComplete
                        handleQueryChange={handleAddressChange}
                        label={"Votre adresse"}
                        handleOnAddressSelect={handleAddressChange}
                        hasError={addressHasError}
                        address={address}
                        selectedDefaultAddress={address}
                    />
                    {!available && (
                        <Typography className={classes.warningText}>
                            « Désolée, ecosystem ne propose pas encore ce service dans votre
                            commune »
                        </Typography>
                    )}
                    {hasErrorAddress && available && (
                        <Typography className={classes.errorText}>
                            Veuillez sélectionner une adresse
                        </Typography>
                    )}
                    <div className="select-list">
                        <InputLabel id="demo-mutiple-name-label" className="field-title">
                            Votre électroménager(*):
                        </InputLabel>
                        {hasError && (
                            <Typography className={classes.errorText}>
                                Veuillez sélectionner votre électroménager*
                            </Typography>
                        )}
                        <VerticalScroll list={list} handleCheck={handleChange} />
                    </div>



                </form>
            </div>
            <PrimaryButton
                next={validateData}
                hasPrevious={false}
                label={'Suivant'}
                disabled={false} />
        </>
    );
};
Step1.propTypes = {
    nextStep: PropTypes.func,
    save: PropTypes.func,
    step: PropTypes.object,
};
export default Step1;
