import { applyMiddleware, combineReducers, createStore } from 'redux';
import OrderMiddleware from './order/middleware';
import OrderReducer from './order/reducer';
const reducers = combineReducers({
    order: OrderReducer
});

const middlewares = applyMiddleware(OrderMiddleware);

export default createStore(reducers, middlewares);
