import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import React, { useEffect } from 'react';
import PrimaryButton from '../../components/Button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CustomizedSwitches from '../../components/Switcher';
import ReactGA from 'react-ga';
import parsePhoneNumber from 'libphonenumber-js'
// import PhoneNumber from 'awesome-phonenumber';
import PropTypes from 'prop-types';
import BacArrow from '../../assets/images/backarrow.svg';
const etages = ['RDC', '1', '2', '3', '4', '5'];
const Step3 = ({
    nextStep,
    previousStep,
    save,
    step,
    token,
    signUp,
    signUpError,
    resetToken,
    previous,
    setprevious

}) => {
    const [valid, setValid] = React.useState(false);
    const [emailValid, setEmailValid] = React.useState(true);
    const [phoneValid, setPhoneValid] = React.useState(true);
    const [client, setClient] = React.useState({
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        floor: null,
        collectAddress: null,
        doorKey: null,
        elevator: false
    });
    useEffect(() => {
        ReactGA.event({
            category: 'Utilisateur',
            action: 'Ecosystem - Step 3'
        });
    }, []);
    useEffect(() => {
        if (step) {
            const ct = {
                ...step,
                doorKey: client.collectAddress === 'Maison' ? null : client.doorKey
            };
            setClient(ct);
            verify(step);

        }
    }, [step]);
    useEffect(() => {
        if (previous == 4) {
            resetToken();
        } else if (token) {
            nextStep();
        }
    }, [previous, token]);
    useEffect(() => {
        verify(client);
    }, [client,phoneValid]);

    const handleClientChange = (event) => {

        console.log('event', event, event.target.value, event.target.value === '');
        if (event.target.value || event.target.value === '') {
            let ct = {
                ...client,
                [event.target.name]:
                    event.target.value === '' ? null : event.target.value
            };
            if (event.target.name === 'email') {
                setEmailValid(validateEmail(event.target.value));
            }
            if (event.target.name === 'floor') {
                ct = {
                    ...ct,
                    elevator: event.target.value !== 'RDC'
                };
            }
            setClient(ct);
            verify(ct);
        }
    };
    const handlePhoneChange = (value) => {
        const parsedNumber = parsePhoneNumber(value, 'FR');
        // var pn = new PhoneNumber(
        //     value[0] === '+' ? value.substring(1) : value,
        //     `+${value}`.includes('+216') ? 'TN' : 'FR'
        // );   
        setPhoneValid(
            parsedNumber ? parsedNumber.isValid() : false
        );
        if (value) {
            const ct = {
                ...client,
                phone: `+${value}`
            };
            setClient(ct);
            verify(ct);
        }
    };
    const verify = (data) => {
        let isValid = true;
        for (const [key, value] of Object.entries(data)) {
            if (key !== 'doorKey') {
                if (value === null || value === '') {
                    isValid = key === 'floor' && data.collectAddress === 'Maison';
                }
            }
        }
        console.log('client.phone', client.phone, phoneValid);
        setValid(isValid && emailValid && client.phone !== null && phoneValid && client.email !== null && client.firstname !== null && client.lastname !== null);
    };
    const saveStep = () => {
        const ct = {
            ...client,
            doorKey: client.collectAddress === 'Maison' ? null : client.doorKey
        };
        save(ct);
        signUp(client.phone, client.email);
        setprevious(3)
        /*  nextStep()*/
    };
    const handleElevatorChange = (value) => {
        const ct = {
            ...client,
            elevator: value
        };
        setClient(ct);
    };
    // const validatephone = (phone) => {
    //     const re =
    //         /^[+](\d{3})\)?(\d{3})(\d{5,6})$|^(\d{10,10})$/;
    //     return re.test(String(phone).toLowerCase());
    // };
    const validateEmail = (email) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    return (
        <>
            <div style={{ padding: '0px 10px 0px 10px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={BacArrow}
                        onClick={previousStep}
                        style={{ marginTop: '15px', paddingRight: '13px', cursor: 'pointer' }}
                    />
                    <Typography style={{ fontSize: 20, fontWeight: 500, marginTop: 15 }}>
                        {' '}
                        Vos informations
                    </Typography>
                </div>
                <Typography style={{ fontSize: 16, fontWeight: 400, paddingTop: 15 }}>
                    {' '}
                    Pour venir chez vous, il nous faut les informations suivantes:
                </Typography>
                <div style={{ marginTop: 20 }}>
                    <Typography style={{ fontSize: 15, fontWeight: 400 }}>
                        {' '}
                        Vos coordonées
                    </Typography>
                    <TextField
                        style={{ marginTop: 10 }}
                        label="Prenom*"
                        name="firstname"
                        id="outlined-size-small"
                        value={client.firstname}
                        onChange={handleClientChange}
                        variant="outlined"
                        placeholder="Votre prenom"
                        fullWidth
                    />
                    <TextField
                        style={{ marginTop: 20 }}
                        label="Nom*"
                        name="lastname"
                        id="outlined-size-small"
                        value={client.lastname}
                        onChange={handleClientChange}
                        variant="outlined"
                        placeholder="Votre nom"
                        fullWidth
                    />
                    <TextField
                        style={{ marginTop: 20 }}
                        label="Email*"
                        name="email"
                        id="outlined-size-small"
                        value={client.email}
                        onChange={handleClientChange}
                        variant="outlined"
                        placeholder="Votre adresse email"
                        fullWidth
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        error={!emailValid}
                    />
                    <PhoneInput
                        placeholder="Enter phone number"
                        name="phone"
                        value={client.phone}
                        onChange={(phone) => handlePhoneChange(phone)}
                        country={'fr'}
                        isValid={phoneValid}
                        enableAreaCodes={true}
                        autocompleteSearch={true}
                        onlyCountries={['fr']}
                        style={{fontSize: '16px'}}
                        inputProps={{
                            name: 'phone',
                            required: true,
                            country: 'fr'
                        }}

                    />
                </div>
                <div style={{ marginTop: 40, display: 'grid' }}>
                    <Typography style={{ fontSize: 15, fontWeight: 400 }}>
                        {"L'adresse de collecte"}
                    </Typography>
                    <FormControl variant="outlined" style={{ marginTop: 20 }}>
                        <InputLabel id="demo-simple-select-outlined-label">
                            Maison ou appartement ?*
                        </InputLabel>
                        <Select
                            value={client.collectAddress || ''}
                            name="collectAddress"
                            onChange={handleClientChange}
                            label="Maison ou appartement ?*"
                        >
                            <MenuItem
                                value={'Maison'}
                                selected={client.collectAddress === 'Maison'}
                            >
                                <span style={{ paddingLeft: 10 }}>Maison</span>
                            </MenuItem>
                            <MenuItem
                                value={'Appartement'}
                                selected={client.collectAddress === 'Appartement'}
                            >
                                <span style={{ paddingLeft: 10 }}>Appartement</span>
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {client.collectAddress === 'Appartement' && <FormControl variant="outlined" style={{ marginTop: 20 }}>
                        <InputLabel id="demo-simple-select-outlined-label">
                            {`Etage*`}
                        </InputLabel>
                        <Select
                            value={client.floor || ''}
                            onChange={handleClientChange}
                            label="Etage"
                            name="floor"
                        >
                            {etages.map((elem, i) => (
                                <MenuItem value={elem} style={{ fontSize: 14 }} key={i}>
                                    <span style={{ paddingLeft: 10 }}>{elem}</span>
                                </MenuItem>
                            ))}
                        </Select>
                        {client.floor && client.floor !== 'RDC' && (
                            <CustomizedSwitches
                                checked={client.elevator}
                                handleCheck={handleElevatorChange}
                            />
                        )}
                    </FormControl>}

                    {client.collectAddress === 'Appartement' && client.collectAddress && (
                        <TextField
                            style={{ marginTop: 20 }}
                            label="Code porte"
                            value={client.doorKey}
                            id="outlined-size-small"
                            name="doorKey"
                            onChange={handleClientChange}
                            variant="outlined"
                            placeholder="Code porte"
                        />
                    )}
                    <Typography
                        style={{
                            fontSize: 15,
                            fontWeight: 400,
                            padding: '5px',

                        }}
                    >
                        (1) Si vous vivez au-delà du 5ème étage sans ascenseur, notre équipe
                        ne pourra prendre votre appareil au sein de votre domicile. Nous vous
                        remercions dans ce cas présent, de le descendre au rez-de-chaussée.
                    </Typography>
                </div>

                {!token && signUpError && <p className="error-msg"> {signUpError}</p>}
            </div>
            <PrimaryButton
                next={saveStep}
                hasPrevious={false}
                label={!token && signUpError ? 'Réessayer' : 'Suivant'}
                disabled={!valid}
            />
        </>
    );
};
Step3.propTypes = {
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    save: PropTypes.func,
    step: PropTypes.object,
    token: PropTypes.any,
    signUp: PropTypes.func,
    signUpError: PropTypes.any,
    resetToken: PropTypes.func,
    previous: PropTypes.number,
    setprevious: PropTypes.func

};
export default Step3;
