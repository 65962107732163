export const SAVE_STEP_1 = 'SAVE_STEP_1';
export const SAVE_STEP_2 = 'SAVE_STEP_2';
export const SAVE_STEP_3 = 'SAVE_STEP_3';
export const SAVE_STEP_4 = 'SAVE_STEP_4';
export const SAVE_ATTACHEMENTS = 'SAVE_STEP_4';

export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

export const UPLOAD_ATTACHEMENTS = 'UPLOAD_ATTACHEMENTS';
export const UPLOAD_ATTACHEMENTS_SUCCESS = 'UPLOAD_ATTACHEMENTS_SUCCESS';
export const UPLOAD_ATTACHEMENTS_FAILURE = 'UPLOAD_ATTACHEMENTS_FAILURE';

export const GET_SALESCHANNEL_CONFIG = 'GET_SALESCHANNEL_CONFIG';
export const GET_SALESCHANNEL_CONFIG_SUCCESS =
  'GET_SALESCHANNEL_CONFIG_SUCCESS';
export const GET_SALESCHANNEL_CONFIG_FAILURE =
  'GET_SALESCHANNEL_CONFIG_FAILURE';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const RESET_TOKEN = 'RESET_TOKEN';
export const saveStep1 = (payload) => ({
    type: SAVE_STEP_1,
    payload
});
export const saveStep2 = (payload) => ({
    type: SAVE_STEP_2,
    payload
});
export const saveStep3 = (payload) => ({
    type: SAVE_STEP_3,
    payload
});
export const saveStep4 = (payload) => ({
    type: SAVE_STEP_4,
    payload
});
export const saveAttachements = (payload) => ({
    type: SAVE_ATTACHEMENTS,
    payload
});
export const createOrder = () => ({
    type: CREATE_ORDER
});
export const createOrderSuccess = (data) => ({
    type: CREATE_ORDER_SUCCESS,
    payload: { data }
});
export const createOrderFailure = (error) => ({
    type: CREATE_ORDER_FAILURE,
    payload: { error }
});
export const uploadAttachements = () => ({
    type: UPLOAD_ATTACHEMENTS
});

export const getSalesChannelsConfig = () => ({
    type: GET_SALESCHANNEL_CONFIG
});

export const getSalesChannelsConfigSuccess = (data) => ({
    type: GET_SALESCHANNEL_CONFIG_SUCCESS,
    payload: { data }
});
export const getSalesChannelsConfigFailure = (error) => ({
    type: GET_SALESCHANNEL_CONFIG_FAILURE,
    payload: { error }
});

// eslint-disable-next-line camelcase
export const signUp = (client_id, client_secret) => ({
    type: SIGNUP,
    // eslint-disable-next-line camelcase
    payload: { client_id, client_secret }
});
export const signUpSuccess = (data) => ({
    type: SIGNUP_SUCCESS,
    payload: { data }
});
export const signUpFailure = (error) => ({
    type: SIGNUP_FAILURE,
    payload: { error }
});
export const resetToken = () => ({
    type: RESET_TOKEN,
    
});