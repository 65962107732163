import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@material-ui/core';
import QuantityBadge from './QuantityBadge';
import PropTypes from 'prop-types';

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '100%',
        '&.MuiTabScrollButton-vertical': {
            height: '10px'
        },
        flexGrow: 1,
        display: 'flex',
        height: '360px'

    },
    produitLine: {
        minWidth: '100%',
        padding: '0px',
        marginBottom: '8px',
        boxShadow: '0 3px 3px 0 #F5F5F9',
        borderRadius: '5px',

    }
}));
const Item = ({ item, handleChange }) => {
    const [Item, setItem] = useState(item);
    const [count, setCount] = useState(Item.quantity);
    const handleCounter = (number) => {
        setCount(number);
        handleChange(Item.id, number);
    };
    useEffect(() => {
        setItem(item);
        setCount(item.quantity);
    }, [item]);
    return (
        <ListItem component="div">
            <ListItemAvatar>

                {/* <div
                    style={{

                        border: '1px solid #F9F9FB',
                        width: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '5px'
                    }}
                > */}
                <img src={Item.src} alt={Item.label} style={{ width: 50 }} />
                {/* </div> */}

            </ListItemAvatar>
            <ListItemText primary={Item.label} style={{
                whiteSpace: 'pre-wrap', textTransform: 'initial'
            }} />
            <QuantityBadge counter={count} handleCounter={handleCounter} />
        </ListItem>
    );
};
Item.propTypes = {
    item: PropTypes.object,
    handleChange: PropTypes.func
};
export default function VerticalTabs({ list, handleCheck }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        // <div className={classes.root}>
        <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.root}
            TabIndicatorProps={{ style: { background: "transparent" } }}
        >
            {list.map((item, i) => (
                <Tab
                    label={<Item item={item} handleChange={handleCheck} />}
                    {...a11yProps(i)}
                    key={`tab_${i}`}
                    className={classes.produitLine}
                />
            ))}
        </Tabs>
        // </div>
    );
}
VerticalTabs.propTypes = {
    list: PropTypes.array,
    handleCheck: PropTypes.func
};
