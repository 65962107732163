import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {
    ListItemAvatar,
    ListItem,
    ListItemText,
    makeStyles,
} from '@material-ui/core';
import BackArrow from '../assets/images/backarrow.svg';

const useStyles = makeStyles(() => ({
    root: {
        padding: '0px 50px 10px 25px'
    },
    item: {
        cursor: 'pointer',
        borderRadius: '5px',
        padding: '10px !important',

        width: '230px',
        '&:hover': {
            backgroundColor: '#F0F3FA !important',
        },
    },
    avatar: {
        minWidth: '45px'
    }
}));
export default function StateModal({
    handleClose,
    open,
    name,
    list,
    validata,
    idProduit,
    idAppareil
}) {
    const classes = useStyles();


    const suivantMethode = (value) => {
        if (value) {
            validata(value, idProduit, idAppareil);
            handleClose();
        }
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '18px',
                        fontWeight: '500'
                    }}
                >
                    <img
                        src={BackArrow}
                        onClick={handleClose}
                        style={{ paddingRight: '13px', cursor: 'pointer', marginLeft: '10px' }}
                    />
                    {name}
                </div>
            </DialogTitle>
            <DialogContent className={classes.root}>
                <div>
                    {list.map((item, i) => (
                        <ListItem
                            key={i}
                            className={classes.item}
                            onClick={
                                () => {
                                    suivantMethode(item.label);

                                }
                            }
                        ><ListItemAvatar className={classes.avatar}>
                                <img src={item.src} alt={item.label} style={{ width: 20 }} />
                            </ListItemAvatar>
                            <ListItemText primary={item.label} />
                        </ListItem>
                    ))}
                </div>
            </DialogContent>

        </Dialog>
    );
}
StateModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    list: PropTypes.array,
    name: PropTypes.string,
    setAge: PropTypes.func,
    validata: PropTypes.func,
    idProduit: PropTypes.string,
    idAppareil: PropTypes.number

};
