import {
    CREATE_ORDER,
    createOrderFailure,
    createOrderSuccess,
    GET_SALESCHANNEL_CONFIG,
    getSalesChannelsConfigFailure,
    getSalesChannelsConfigSuccess,
    SIGNUP,
    signUpFailure,
    signUpSuccess,
    UPLOAD_ATTACHEMENTS
} from './actions';
import { collectData } from '../../utils';
import axios from './axios';
const ECOSYSTEM = 'ECO-SYSTEM';
const publicApi = process.env.REACT_APP_PUBLIC_API_URL;
const OrderMiddleware = (store) => (next) => (action) => {
    if (action.type === CREATE_ORDER) {
        const {
            order: { step1, step2, step3, step4, token }
        } = store.getState();
        const data = collectData({ step1, step2, step3, step4 });
        axios.interceptors.request.use(
            (config) => {
                config.headers = { 'X-Auth-Token': token };
                return config;
            },
            (error) => Promise.reject(error)
        );
        axios
            .post(`${publicApi}deliveries/create`, data)
            .then(({ data }) => store.dispatch(createOrderSuccess(data)))
            .catch((err) => store.dispatch(createOrderFailure(err)));
    }
    if (action.type === UPLOAD_ATTACHEMENTS) {
        const {
            order: { step2, codeCourse }
        } = store.getState();
        let product = [];
        Object.values(step2.allData).map((element) => {
            Object.values(element.produits).map((obj) => {
                obj?.file && obj?.file !== null && product.push(obj?.file);
            });
        });
        product.forEach((file) => {
            let formData = new FormData();
            formData.append(`file`, file);
            const headers = {
                'Content-Type': 'multipart/form-data'
            };
            axios
                .post(
                    `${publicApi}attachments/add?codeCourse=${codeCourse}`,
                    formData,
                    {
                        headers
                    }
                )
                .then(() => console.log('file uploaded !!! '))
                .catch((err) => store.dispatch(createOrderFailure(err)));
        });
    }
    if (action.type === GET_SALESCHANNEL_CONFIG) {
        axios
            .get(`${publicApi}getCanalVenteInfos?codeCanal=${ECOSYSTEM}`)
            .then(({ data }) => store.dispatch(getSalesChannelsConfigSuccess(data)))
            .catch((err) => store.dispatch(getSalesChannelsConfigFailure(err)));
    }
    if (action.type === SIGNUP) {
        const {
            order: { step3 }
        } = store.getState();
        const { email, phone } = step3;
        const data = {
            // eslint-disable-next-line camelcase
            client_id: phone,
            // eslint-disable-next-line camelcase
            client_secret: email
        };
        axios
            .post(`${publicApi}signup`, data)
            .then(({ data }) => store.dispatch(signUpSuccess(data)))
            .catch((err) => store.dispatch(signUpFailure(err)));
    }
    next(action);
};
export default OrderMiddleware;
