import { Button } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(() => ({
    button: {
        width: '192px',
        height: '50px',
        backgroundColor: '#FF6C23',
        borderRadius: '8px',
        opacity: 1,
        color: '#ffff'
    },
    link: {
        textAlign: 'center',
        color: '#1976D2',
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    navButton: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'grid'
    }
}));
const PrimaryButton = ({ next, previous, hasPrevious, label, disabled }) => {
    const classes = useStyles();
    return (
        <div style={{
            display: 'flex', justifyContent: 'end', boxShadow: ' rgb(116 116 116 / 54%) 0px 0px 12px',
            padding: ' 10px 10px 10px'
        }}>
            <Button
                variant="contained"
                className={classes.button}
                onClick={next}
                disabled={disabled}
            >
                {label}
            </Button>
            {hasPrevious && (
                <div className={classes.link} onClick={previous}>
                    {' '}
                    Retour{' '}
                </div>
            )}
        </div>
    );
};
PrimaryButton.propTypes = {
    next: PropTypes.func,
    previous: PropTypes.func,
    hasPrevious: PropTypes.bool,
    label: PropTypes.string,
    disabled: PropTypes.bool
};
export default PrimaryButton;
