import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';

const OrangeSwitcher = withStyles({
    switchBase: {
        color: '#f37547',
        '&$checked': {
            color: '#f37547'
        },
        '&$checked + $track': {
            backgroundColor: '#f37547'
        }
    },
    checked: {},
    track: {}
})(Switch);

const CustomizedSwitches = ({ checked, handleCheck }) => {
    const [state, setState] = React.useState(checked);
    useEffect(() => {
        setState(checked);
    }, [checked]);
    const handleChange = (event) => {
        handleCheck(event.target.checked);
    };

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <OrangeSwitcher
                        checked={state}
                        onChange={handleChange}
                        name="elevator"
                    />
                }
                label="Présence Ascenseur"
            />
        </FormGroup>
    );
};
CustomizedSwitches.propTypes = {
    checked: PropTypes.bool,
    handleCheck: PropTypes.func
};
export default CustomizedSwitches;
