import React from 'react';

import { CircularProgress, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
/** A  circular progress loading ui element */
const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 100,
        height: 100,
        Zindex: 2222,
        top: 0,
        bottom: 0,
        left: 120,
        margin: 'auto',
        position: 'fixed',
        backgroundColor: 'transparent',
        borderRadius: 10,
        opacity: 0.5
    }
});
export default function Spinner() {
    const theme = useTheme();
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className="spinner-border" role="status">
                <CircularProgress style={{ color: theme.palette.primary.secondary }} />
            </div>
        </div>
    );
}
