import Step1 from './Step1';
import { useEffect, useState } from 'react';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Spinner from '../../components/Spinner';
import { Typography } from '@material-ui/core';
import failImg from './../../assets/404.svg';
import Button from '../../components/Button';
import React from 'react';
import PropTypes from 'prop-types';
import { uuid } from 'uuidv4';
import { useHash } from 'react-use';
const Order = ({
    step1,
    saveStep1,
    step2,
    saveStep2,
    step3,
    saveStep3,
    step4,
    saveStep4,
    createOrder,
    loading,
    created,
    codeCourse,
    uploadAttachements,
    getSalesChannelsConfig,
    salesChannelConfig,
    token,
    signUp,
    resetToken,
    signUpError
}) => {
    const [hash, setHash] = useHash();
    const [activeStep, setActiveStep] = useState(1);
    const [previous, setprevious] = useState(1);
    useEffect(() => {
        setHash("step=1");
    }, []);
    useEffect(() => {
        var step = hash.split('#').pop().split('=').pop();
        if (step) {
            setActiveStep(Number(step));
            setHash(`step=${step}`);
        }  
    }, [hash]);

    useEffect(() => {
        getSalesChannelsConfig();
    }, []);
    useEffect(() => {
        if (step1 == null && step2 == null && step3 == null && step4 == null) {
            setHash('step=1');
            setActiveStep(1);
            return;
        }
        if(activeStep == 4  ){
            setprevious(4)
        }
    }, [activeStep]);

    const nextStep = () => {
        setActiveStep(activeStep + 1);
        setHash(`step=${activeStep + 1}`);
        window.scrollTo(0, 0);
    };
    const previousStep = () => {
        setHash(`step=${activeStep - 1}`);
        setActiveStep(activeStep - 1);
    };
    const reset = () => {
        if (!created && activeStep === 5) {
            setActiveStep(1);
        }
    };
    const tabData = () => {
        let produits = [];
        let data = [];
        step1.articles
            .filter(({ quantity }) => quantity >= 1)
            .map((article) => {
                [...Array(article.quantity)].map(() => {
                    produits.push({
                        code: article.code,
                        idProduit: uuid(),
                        age: null,
                        state: null,
                        quantity: 1,
                    });
                });
                data.push({
                    ...article,
                    produits: produits.reduce((a, v) => ({ ...a, [v.idProduit]: v }), {})
                });

                produits = [];
            });
        let appareils = data.reduce((a, v) => ({ ...a, [v.id]: v }), {});

        return appareils;
    };
    useEffect(() => {
        if (created && codeCourse && step2) {
            uploadAttachements();
        }
    }, [created, codeCourse]);

    if (loading) return <Spinner />;
    return (
        <div className="steps-container">
            {activeStep == 1 && (
                <Step1 nextStep={nextStep} save={saveStep1} step={step1} />
            )}
            {activeStep == 2 && (
                <Step2
                    nextStep={nextStep}
                    previousStep={previousStep}
                    step={step2}
                    save={saveStep2}
                    data={tabData()}

                />
            )}
            {activeStep == 3 && (
                <Step3
                    nextStep={nextStep}
                    previousStep={previousStep}
                    step={step3}
                    save={saveStep3}
                    token={token}
                    signUp={signUp}
                    signUpError={signUpError}
                    resetToken={resetToken}
                    previous={previous}
                    setprevious={setprevious}
                />
            )}
            {activeStep == 4 && (
                <Step4
                    nextStep={nextStep}
                    previousStep={previousStep}
                    step={step4}
                    save={saveStep4}
                    createOrder={createOrder}
                    salesChannelConfig={salesChannelConfig}
                />
            )}
            {activeStep == 5 && !loading && (
                <React.Fragment>
                    {created ? (
                        <Step5 nextStep={nextStep} previousStep={previousStep} />
                    ) : (
                        <Fail reset={reset} />
                    )}
                </React.Fragment>
            )}
        </div>
    );
};
Order.propTypes = {
    created: PropTypes.any,
    codeCourse: PropTypes.any,
    uploadAttachements: PropTypes.func,
    getSalesChannelsConfig: PropTypes.func,
    salesChannelConfig: PropTypes.object,
    token: PropTypes.any,
    signUp: PropTypes.func,
    signUpError: PropTypes.any,
    step1: PropTypes.object,
    saveStep1: PropTypes.func,
    step2: PropTypes.object,
    saveStep2: PropTypes.func,
    step3: PropTypes.object,
    saveStep3: PropTypes.func,
    step4: PropTypes.object,
    saveStep4: PropTypes.func,
    createOrder: PropTypes.func,
    loading: PropTypes.bool,
    resetToken: PropTypes.func
};
export default Order;
const Fail = ({ reset }) => {
    return (
        <div>
            <Typography style={{ textAlign: 'center', padding: 20 }}>
                {"Oups, une erreur s'est produite. Merci de réessayer plus tard."}
            </Typography>
            <img
                src={failImg}
                alt="fail-img"
                style={{ width: 200, margin: 'auto 40px' }}
            />
            <Button
                disabled={false}
                label="Réessayer"
                next={reset}
                hasPrevious={false}
            />
        </div>
    );
};
Fail.propTypes = {
    reset: PropTypes.func
};
