import _ from 'lodash';
import moment from 'moment';
import PhoneNumber from 'awesome-phonenumber';
import secheLing from './assets/images/Sèche-linge.svg';
import frigo from './assets/images/frigo.svg';
import frigoUs from './assets/images/frigo-us.svg';
import laveVaisselle from './assets/images/Lave-vaisselle_ouvert.svg';
import laveLinge from './assets/images/Lave-linge.svg';
import hote from './assets/images/Hotte aspirante.svg';
import four from './assets/images/Four-encastrable.svg';
import cuisiniere from './assets/images/Cuisinière avec plaques à induction.svg';
import congelateur from './assets/images/Congélateur.svg';
import caveVin from './assets/images/Caves à vin.svg';
import plaque from './assets/images/Plaques.png';
import fourMicroOndes from './assets/images/Micro-ondes.png';
import reparable from './assets/images/reparable.svg';
import recyclage from './assets/images/recyclage.svg';
import fonctionnel from './assets/images/fontionnel.svg';
import moins2 from './assets/images/moins2.svg';
import moins5 from './assets/images/moin5.svg';
import plus6 from './assets/images/plus6.svg';
import plus10 from './assets/images/plus10.svg';
export class Country {
    constructor(code, name) {
        this.code = code;
        this.name = name;
    }
    getCountry() {
        return new Country();
    }
}
export const AVAILABLE_POSTAL_CODES_GROUPE = [
    '75',
    '92',
    '94',
    '93',
    '91',
    '78',
    '95'
];
export const AVAILABLE_POSTAL_CODES = [
    '92100',
    '92200',
    '92310',
    '92312',
    '92410',
    '92190',
    '92360',
    '92075',
    '92130',
    '92370',
    '92170',
    '92150',
    '92270',
    '94480',
    '94110',
    '94230',
    '94550',
    '94600',
    '94260',
    '94250',
    '94200',
    '94270',
    '94240',
    '94310',
    '94150',
    '94320',
    '94460',
    '94800',
    '94290',
    '94190',
    '94400',
    '92380',
    '93600',
    '93420',
    '93440',
    '93150',
    '93350',
    '93270',
    '93290',
    '93390',
    '93470',
    '93220',
    '93460',
    '93340',
    '93190',
    '93370',
    '94490',
    '94520',
    '94440',
    '94370',
    '78870',
    '91570',
    '78390',
    '78380',
    '78530',
    '78117',
    '78330',
    '78350',
    '78170',
    '78150',
    '78590',
    '78210',
    '78140',
    '78000',
    '92430',
    '93360',
    '93330',
    '93160',
    '93320',
    '93110',
    '93410',
    '93250',
    '94000',
    '94140',
    '94470',
    '94380',
    '94430',
    '94510',
    '94420',
    '94880',
    '78220',
    '94450',
    '92400',
    '92500',
    '92330'
];
export const statutList = [
    {
        label: 'Fonctionnel',
        src: fonctionnel,
    },
    {
        label: 'Réparable',
        src: reparable,
    },
    {
        label: 'Bon pour recyclage',
        src: recyclage,
    },
];
export const ageList = [{
    label: 'Moins de 2 ans',
    src: moins2,
}, {
    label: 'Entre 2 et 5 ans',
    src: moins5,
},
{
    label: 'Entre 6 et 10 ans',
    src: plus6,
},
{
    label: 'Plus de 10 ans',
    src: plus10,
}
];
export const LIST = [
    {
        code: 'RF',
        label: 'Réfrigérateur',
        src: frigo,
        quantity: 0,
        id: 0
    },
    {
        code: 'LL',
        label: 'Lave linge',
        src: laveLinge,
        quantity: 0,
        id: 1
    },
    {
        code: 'FM',
        label: 'Four à micro-ondes',
        src: fourMicroOndes,
        quantity: 0,
        id: 2
    },
    {
        code: 'LV',
        label: 'Lave vaiselle',
        src: laveVaisselle,
        quantity: 0,
        id: 3
    },
    {
        code: 'CU',
        label: 'Cuisinière',
        src: cuisiniere,
        quantity: 0,
        id: 4
    },
    {
        code: 'FE',
        label: 'Four encastrable',
        src: four,
        quantity: 0,
        id: 5
    },
    {
        code: 'CG',
        label: 'Congélateur',
        src: congelateur,
        quantity: 0,
        id: 6
    },
    {
        code: 'SL',
        label: 'Sèche linge',
        src: secheLing,
        quantity: 0,
        id: 7
    },
    {
        code: 'PC',
        label: 'Plaque de cuisson',
        src: plaque,
        quantity: 0,
        id: 8
    },
    {
        code: 'HA',
        label: 'Hotte aspirante',
        src: hote,
        quantity: 0,
        id: 9
    },
    {
        code: 'RFA',
        label: 'Réfrigérateur américain',
        src: frigoUs,
        quantity: 0,
        id: 10
    },
    {
        code: 'CV',
        label: 'Cave à vin',
        src: caveVin,
        quantity: 0,
        id: 11
    }
];
export const GOOGLE_DEFAULT_COUNTRY = { code: 'fr', name: 'France' };
export const GOOGLE_COUNTRIES = [
    GOOGLE_DEFAULT_COUNTRY,
    { code: 'ch', name: 'Suisse (Switzerland)' },
    { code: 'be', name: 'Belgique (Belgium)' },
    { code: 'de', name: 'Allemagne (Germany)' },
    { code: 'es', name: 'Espagne (Spain)' },
    { code: 'it', name: 'Italie (Italy)' },
    { code: 'lu', name: 'Luxembourg' },
    { code: 'nl', name: 'Pays-Bas (Netherlands)' },
    { code: 'pt', name: 'Portugal' }
];
export const DAYS_OF_WEEK = Object.freeze([
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY'
]);
export const getAddress = (address) => {
    // eslint-disable-next-line camelcase
    const { address_components, geometry, formatted_address } = address;
    let data = {
        // eslint-disable-next-line camelcase
        address: formatted_address,
        codePostal: null,
        country: null,
        city: null,
        latitude: geometry && geometry.location ? geometry.location.lat : null,
        longitude: geometry && geometry.location ? geometry.location.lng : null
    };
    // eslint-disable-next-line camelcase
    address_components.forEach(({ types, short_name }) => {
        if (types.includes('country')) {
            // eslint-disable-next-line camelcase
            data.country = short_name;
        }
        if (types.includes('postal_code')) {
            // eslint-disable-next-line camelcase
            data.codePostal = short_name;
        }
        if (types.includes('locality')) {
            // eslint-disable-next-line camelcase
            data.city = short_name;
        }
    });
    return data;
};
export const getAddressPostalCode = (address) => {
    // eslint-disable-next-line camelcase
    const { address_components } = address;
    let postalCode = null;
    // eslint-disable-next-line camelcase
    address_components.forEach(({ types, short_name }) => {
        if (types.includes('postal_code')) {
            // eslint-disable-next-line camelcase
            postalCode = short_name;
        }
    });
    return postalCode;
};
export const collectData = (steps) => {
    const { step1, step2, step3, step4 } = steps;
    const { address, codePostal, country, city } = getAddress(step1.address);
    const {
        collectAddress,
        doorKey,
        email,
        firstname,
        floor,
        lastname,
        phone,
        elevator
    } = step3;
    const data = step2;
    let product = [];
    Object.values(data.allData).map((element) => {
        Object.values(element.produits).map((obj) => {
            product.push(obj);
        });
    });
    const { start, end } = step4;
    var pn = new PhoneNumber(
        phone[0] === '+' ? phone.substring(1) : phone,
        phone.includes('+216') ? 'TN' : 'FR'
    );

    console.log('testing', pn.getNumber('international'));
    const packages = product.map(({ label, quantity, code, age, state }) => {
        return {
            height: {
                unit: 'cm',
                value: 0
            },
            length: {
                unit: 'cm',
                value: 0
            },
            width: {
                unit: 'cm',
                value: 0
            },
            products: [
                {
                    type: 'TYPOLOGY_GENERIC',
                    label,
                    code,
                    age,
                    state
                }
            ],
            quantity
        };
    });
    let articlesListComment = '';
    if (step1?.articles?.length > 0) {
        step1.articles
            .filter(({ quantity }) => quantity > 0)
            .forEach(({ label, quantity }) => {
                articlesListComment = articlesListComment + `, ${label} X ${quantity} `;
            });
    }
    let services = [];
    if (floor !== 'RDC') {
        services.push('MANUT');
    }
    let elevatorDescription = '';
    if (elevator !== null && floor !== 'RDC') {
        if (elevator === true) {
            elevatorDescription = ' ,Présence Ascenseur: Oui';
        }
        if (elevator === false) {
            elevatorDescription = ' ,Présence Ascenseur: Non';
        }
    }
    return {
        deliverySource: 'Ecosystem',
        orderId: '',
        referenceNumber: '',
        externalCanalCode: 'Ecosystem',
        retailer: {},
        picking: {
            address: {
                city: city,
                floor: floor,
                country: country,
                district: null,
                elevator: elevator !== null && floor !== 'RDC' ? elevator : null,
                postalCode: codePostal,
                addressLine1: address,
                addressLine2: '',
                comment: `L'adresse de collecte: ${collectAddress}${floor ? ' ,Étage: ' : ''
                    }${floor ? floor : ''}${elevatorDescription}${doorKey ? ' ,Code porte: ' : ''
                    }${doorKey ? doorKey : ''
                    }, l’état d'électroménagers  ${articlesListComment}`
            },
            contact: {
                firstName: firstname,
                lastName: lastname,
                phone: pn.getNumber('international'),
                email: email
            },
            interval: [{ end, start }]
        },
        delivery: {},
        packages,
        services,
        tags: [
            {
                'key': 'EdiType',
                'value': 'PICKUP'
            }
        ],
        courseType: 'ClientPickup'
    };
};
const getSignleCreneau = (date, time, deliveryWindow) => {
    const hours = deliveryWindow / 60;
    const hour = parseInt(time, 10);
    const end = moment(date)
        .startOf('day')
        .add(hours + hour, 'hours')
        .format('HH');
    return {
        label: `${time}h-${end}h`,
        value: `${time}-${end}`
    };
};
export const getCreneau = (
    selectedDate,
    closeTime,
    deliveryWindow,
    openTime
) => {
    let interval = [];
    let theDay = moment(selectedDate)
        .startOf('day')
        .add(parseInt(openTime, 10), 'hours')
        .format('HH');
    let theEndDay = moment(selectedDate)
        .startOf('day')
        .add(parseInt(closeTime, 10), 'hours')
        .format('HH');
    const nbr = (parseInt(theEndDay) - parseInt(theDay)) / (deliveryWindow / 60);
    const list = Array(Math.trunc(nbr)).fill('');
    interval.push(getSignleCreneau(selectedDate, theDay, deliveryWindow));
    theDay = moment(selectedDate)
        .startOf('day')
        .add(parseInt(openTime, 10) + deliveryWindow / 60, 'hours')
        .format('HH');
    list.forEach((elem, i) => {
        if (i > 0) {
            interval.push(getSignleCreneau(selectedDate, theDay, deliveryWindow));
            theDay = moment(selectedDate)
                .startOf('day')
                .add(parseInt(openTime, 10) + (deliveryWindow * (i + 1)) / 60, 'hours')
                .format('HH');
        }
    });
    return interval;
};
export const getDaysIndex = (days) => {
    if (!_.isNil(days)) {
        return DAYS_OF_WEEK.filter(
            (day) =>
                days.findIndex(
                    (el) =>
                        el.dayOfWeek && el.dayOfWeek !== null && el.dayOfWeek.code === day
                ) < 0
        ).map((el) => (el = DAYS_OF_WEEK.indexOf(el)));
    } else {
        return Object.keys(DAYS_OF_WEEK).map((el) => (el = parseInt(el, 10)));
    }
};
const getNextMinDate = (date, disabledDays, min) => {
    const nextdate = moment(date).add(min, 'day');
    if (
        disabledDays.indexOf(
            new Date(new Date(moment(nextdate).format('YYYY/MM/DD'))).getDay()
        ) < 0
    ) {
        return nextdate;
    } else {
        return getNextMinDate(date, disabledDays, min + 1);
    }
};
export const getMinDate = (minHourBooking, disabledDays) => {
    const min = 0;
    const nowDate = new Date();
    const nowDateMinutes = nowDate.getMinutes();
    const nextDate = nowDateMinutes
        ? moment(nowDate).add(60 - nowDateMinutes, 'minutes')
        : moment(nowDate);
    const minDate = nextDate.add(minHourBooking, 'hours');
    if (disabledDays?.length < 7) {
        if (
            disabledDays.indexOf(
                new Date(
                    new Date(moment(minDate).add(min, 'day').format('YYYY/MM/DD'))
                ).getDay()
            ) < 0
        ) {
            return minDate;
        } else {
            return getNextMinDate(
                moment(new Date(`${moment(minDate).format('YYYY/MM/DD')} 00:00:00`)),
                disabledDays,
                min + 1
            );
        }
    } else {
        return minDate;
    }
};
