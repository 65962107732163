export const getOrderState = ({ order }) => order;

export const getStep1Detail = (state) => getOrderState(state).step1;
export const getStep2Detail = (state) => getOrderState(state).step2;
export const getStep3Detail = (state) => getOrderState(state).step3;
export const getStep4Detail = (state) => getOrderState(state).step4;
export const getLoading = (state) => getOrderState(state).loading;
export const getCreated = (state) => getOrderState(state).created;
export const getCodeCourse = (state) => getOrderState(state).codeCourse;
export const getAttachements = (state) => getOrderState(state).attachements;
export const getSalesChannelConfig = (state) =>
    getOrderState(state).salesChannelConfig;
export const getToken = (state) => getOrderState(state).token;
export const getSignUpError = (state) => getOrderState(state).signUpError;
