import axios from 'axios';

axios.interceptors.response.use(
    function (response) {
        if (parseInt(response.data.code) >= 400) {
            return Promise.reject(response);
        }
        return response;
    },
    function (error) {
        console.log('error in response: ', error);
        // catch 401
        if (error.toString() === 'Error: Request failed with status code 401') {
            localStorage.clear();
            return;
        }
        return Promise.reject(error);
    }
);

export default axios;
